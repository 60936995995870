<template>
    <div>
        <Info :items="items" v-if="!loading && !items.length" @add="$emit('add')" />
        <v-card class="elevation-2">
            
            <v-card-text :class="(updateItem) ? 'pa-0' : 'py-5 px-5'">

                <template v-if="updateItem && !deleteItem">
                    
                    <Update :item="updateItem" @updated="updateItem=null" @cancel="updateItem=null" />

                </template>
                
                <template v-else>

                    <v-alert dense type="info" v-if="activeLimitMet">
                        Only {{ maxActive }} contacts may be active at the same time. You can disable a contact to make another one active.
                    </v-alert>

                    <template v-if="items.length">
                        <v-row>
                            <template v-for="item in items">
                                <v-col cols="12" md="6" lg="6" :key="item.uuid">
                                    <v-card color="white" class="list-card elevation-2">
                                        <v-card-title class="hover-item" @click="(item.unsub) ? '' : updateItem=item">
                                            {{ item.name }}
                                        </v-card-title>
                                        <v-card-subtitle>
                                            {{ item.phone }}
                                            <v-chip v-if="item.unsub" small class="ml-2" color="error">Opted-Out</v-chip>
                                            <v-switch
                                                v-if="!item.unsub"
                                                :loading="switching==item.uuid"
                                                color="success"
                                                @click.prevent="updateActive(item)"
                                                :label="(item.active) ? 'Active' : 'Disabled'"
                                                v-model="item.active"
                                                class="ma-0 pa-0 float-right small-switch"
                                                hide-details
                                                :disabled="!item.active && activeLimitMet"
                                            ></v-switch>
                                        </v-card-subtitle>
                                        <v-card-actions class="card-actions">
                                            <template v-if="!item.unsub">
                                                <v-btn color="#999" small icon @click.prevent="deleteItem=item" :loading="deleting==item.uuid"><v-icon color="error" small>fa fa-trash-alt</v-icon></v-btn>
                                                <v-spacer />
                                                <v-btn color="primary" small @click.native="updateItem=item"><v-icon color="white" small class="mr-2">fa fa-edit</v-icon> Update</v-btn>
                                            </template>
                                            <template v-else>
                                                <v-btn color="#999" small icon @click.prevent="deleteItem=item" :loading="deleting==item.uuid"><v-icon color="error" small>fa fa-trash-alt</v-icon></v-btn>
                                                <v-spacer />
                                                <v-btn color="primary" outlined small text @click.native="reOpt=true">Ask Contact to Opt-In</v-btn>
                                            </template>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </template>
                            <v-col cols="12" md="6" lg="6" v-if="items.length<4">
                                <v-card @click="$emit('add')" min-height="130" color="white" hover outline class="list-card fill-height add-card align-center d-flex" outlined>
                                    <v-card-text class="hover-item text-center">
                                        <v-icon large color="primary">fa fa-plus-circle</v-icon>
                                        <br>
                                        <span class="subtitle-1 primary--text font-weight-medium">Add Another Contact</span>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </template>

                    <v-row class="whiteout" v-if="!items.length">
                        <template v-for="item in initListItems">
                            <v-col cols="12" md="6" lg="6" :key="item.uuid">
                                <v-card color="white" class="list-card" hover disabled>
                                    <div class="d-none pt-3">
                                        <v-avatar :size="($vuetify.breakpoint.smAndDown) ? 40 : 45" class="img-border-dark primary mx-auto elevation-5">
                                            <span class="headline text-uppercase shadowed white--text">{{ item.name[0] }}</span>
                                        </v-avatar>
                                    </div>
                                    <v-card-title>
                                        {{ item.name }}
                                    </v-card-title>
                                    <v-card-subtitle>
                                        {{ item.phone }}
                                        <v-switch color="success" @click.prevent="updateActive(item)" :label="(item.active) ? 'Active' : 'Disabled'" v-model="item.active" class="ma-0 pa-0 float-right" hide-details></v-switch>
                                    </v-card-subtitle>
                                    <v-card-actions class="card-actions">
                                        <v-btn color="#999" small icon><v-icon color="error" small>fa fa-trash-alt</v-icon></v-btn>
                                        <v-spacer />
                                        <v-btn color="primary" small><v-icon color="white" small class="mr-2">fa fa-edit</v-icon> Update</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>

                </template>

            </v-card-text>

        </v-card>

        <Info :items="items" v-if="items.length" />
		<Confirm v-if="deleteItem" title="Remove Contact" msg="Are you sure you want to remove this contact?" :subtitle="(user.contacts.length==1) ? 'Removing your only contact will disable your check-ins.' : 'Removing ' + deleteItem.name" @confirmed="handleDelete()" @closed="deleteItem=null" />
        <v-dialog :value="reOpt" overlay-opacity="0.6" overlay-color="#000" @input="reOpt=false" max-width="500px">
            <v-card>
                <v-card-title class="py-2 dcard-title">
                    <h3 class="headline mb-0 primary--text text-center">Undo Opt-Out</h3>
                    <v-btn absolute right icon @click.native="close"><v-icon color="primary">fa fa-times-circle</v-icon></v-btn>
                </v-card-title>
                <v-card-text class="pa-0 pb-2">
                    <v-divider />
                    <v-container pa-0 pt-4 pb-3 px-5>
                        <v-row>
                            <v-col cols="12" class="text-center">
                                <p class="subtitle-1 font-weight-medium lh-14 mb-2">Your contacts may choose to opt-out of receiving text messages from us by texting the word "stop".</p>
                                <p class="body-2 mt-1 mb-2">
                                    To have your contact opt back in, simply ask them to text the word "unstop" to (201) 899-4665. You will be notified if and when they do so.
                                </p>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="card-actions secondary">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="px-10" @click.native="reOpt=false">Got It</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    
import { mapState, mapMutations } from 'vuex'
import Confirm from '@/components/common/Confirm'
import Info from '@/components/user/contacts/Info'
import Update from '@/components/user/contacts/Update'

export default {
    name: 'UserContactsList',
    props: {
        items: {
            required: true,
            type: Array
        },
    },
    components: {
        Confirm,
        Info,
        Update
    },
    computed: {
        ...mapState(['user', 'updatingItem']),
        activeLimitMet(){
            let active = 0;
            for(let i in this.user.contacts){
                if(this.user.contacts[i].active){
                    ++active;
                }
            }
            return active >= this.maxActive;
        },
    },
    data: () => ({
        deleting: null,
        deleteItem: null,
        initListItems: [
            { name: 'Mom', phone: '(555) 555-5555', active: true },
            { name: 'Alex', phone: '(555) 555-5555', active: true },
        ],
        loading: false,
        maxActive: 5,
        switchOn: true,
        switching: null,
        updateItem: null,
        reOpt: false,
    }),
    methods: {
        ...mapMutations(['setUpdatingItem']),
        initData(){
            this.$emit('fetched', this.user.contacts);
            if(this.updatingItem){
                for(let i in this.user.contacts){
                    if(this.user.contacts[i].uuid == this.updatingItem){
                        this.updateItem = this.user.contacts[i];
                    }
                }
                setTimeout(() => {
                    this.setUpdatingItem(null);
                }, 50);
            }
        },
        updateActive(item){
            // this.saving = true;
            // this.errors = null;
            this.switching = item.uuid;
            let request = {
                endpoint: '/contacts/' + item.uuid, 
                method: 'put',
                data: {
                    active: item.active
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your contact has been updated!'
                    });
                    this.$eventHub.$emit('refreshUser');
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.switching = null;
            });
        },
        handleDelete(){
            this.deleting = this.deleteItem.uuid;
            let request = {
                endpoint: '/contacts/' + this.deleteItem.uuid, 
                method: 'post',
                data: {
                    '_method' : 'delete'
                },
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 204){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your contact has been removed.'
                    });
                }
            }).catch(()=>{
            }).finally(()=>{
                this.$eventHub.$emit('refreshUser');
                this.deleting = null;
            });
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
        updateItem(){
            this.$emit('updating', (this.updateItem) ? true : false);
        }
    }
}
</script>