<template>
    <v-card class="elevation-4">
        <v-card-title class="card-header page">
            <v-icon color="primary" class="mr-1">fa fa-plus-circle</v-icon> Add an Emergency Contact - Step {{ step }}
        </v-card-title>
        <v-card-text class="px-5 px-lg-0">
            <v-alert class="mt-2 mx-5" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert>
            <v-stepper v-model="step" class="no-bs">
                <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0">
                        <v-row class="mt-2 mb-1">
                            <v-col cols="12" md="10" lg="8" offset-md="1" offset-lg="2" class="pb-0">
                                <v-text-field
                                    :autofocus="!$vuetify.breakpoint.smAndDown" 
                                    v-model="item.name"
                                    label="Contact Name"
                                    type="text"
                                    color="primary"
                                    outlined
                                    :success="item.name.length > 1"
                                    placeholder="Ex: Mom, Alex, Mr. F"
                                    :rules="[rules.required]"
                                    validate-on-blur
                                    hint="For reference only. Full names are not required."
                                ></v-text-field>
                                <v-row dense>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model="item.phone"
                                            outlined
                                            label="Mobile Phone Number"
                                            type="tel"
                                            class="mt-1"
                                            prefix="+1"
                                            :success="item.phone.length == 14"
                                            placeholder="Ex: (555) 555 - 5555"
                                            :rules="[rules.required, noself]"
                                            validate-on-blur
                                            v-mask="'(###) ###-####'"
                                            hint="Must be able to receive text messages."
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-text-field
                                            v-model="phoneConfirm"
                                            outlined
                                            label="Confirm Phone Number"
                                            type="tel"
                                            class="mt-1"
                                            prefix="+1"
                                            color="primary"
                                            :success="phoneConfirm.length == 14 && phoneConfirm == item.phone"
                                            placeholder="Ex: (555) 555 - 5555"
                                            :rules="[rules.required, confirm]"
                                            validate-on-blur
                                            v-mask="'(###) ###-####'"
                                            hint="Verify the correct phone number was entered."
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" md="10" lg="8" offset-md="1" offset-lg="2" class="pt-0" v-if="user.contacts.length">
                                <v-alert dark color="grey lighten-3" class="mb-0">
                                    <div class="flex-center">
                                        <v-switch v-model="item.active" color="success" light class="mt-0" hide-details label="Contact is Active" />
                                    </div>
                                    <p class="body-2 text-center secondary--text mt-2 mt-md-3 px-1 px-md-3 mb-1">
                                        Only active contacts will receive notifications.
                                        <br>
                                        You can disable a contact to suspend their notifications without deleting them.
                                    </p>
                                </v-alert>               
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                    <v-stepper-content step="2" class="pa-0">
                        <v-row class="mt-0 mb-1 align-center">
                            <v-col cols="12" md="5" class="hidden-sm-and-down">
                                <SMSSim :msgs="smsMsg" />
                            </v-col>
                            <v-col cols="12" md="7" lg="6" class="px-5 px-md-3">
                                <p class="subtitle-1 font-weight-medium secondary--text lh-13 pt-3 pt-md-0 text-center">A message will be sent to <span class="primary--text">{{ item.name || 'your contact' }}</span> at <span class="primary--text">{{ item.phone || 'their number' }}</span> to let them know they've been added as one of your emergency contacts.</p>
                                <div class="text-center" v-show="showPreview">
                                    <SMSSim :msgs="smsMsg" />
                                </div>
                                <p class="text-center hidden-md-and-up"><v-btn small text outlined @click.native="showPreview = !showPreview" color="primary">{{ (showPreview) ? 'Hide Message' : 'Preview Message' }}</v-btn></p>
                                <p class="body-2 secondary--text lh-11">
                                    <ul>
                                        <li class="pb-2">This will be the only notification we send unless you fail to check-in, in which case they will receive an alert to check on you.</li>
                                        <li class="pb-2">You will be notified should this contact chooses to opt-out of (stop) receiving your alerts in the future.</li>
                                        <li>If this contact chooses to opt-out (e.g. they believe the message to be spam, etc.) you will receive instructions on how they can opt-in again.</li>
                                    </ul>
                                </p>
                                <v-divider />
                                <p class="body-2 secondary--text lh-11 pl-2">
                                    <v-radio-group v-model="item.optout" name="optout">
                                        <v-radio class="body-2 font-weight-bold text-center secondary--text inline-checkbox lh-11" color="primary" :value="false" id="optout_false" hide-details="true" label="Notify my new contact that I've added them." />
                                        <v-radio class="body-2 font-weight-bold text-center secondary--text inline-checkbox lh-11" color="primary" :value="true" id="optout_true" hide-details="true" label="Don't notify my new contact, I will let them know myself." />
                                    </v-radio-group>
                                </p>
                                <p class="text-center">
                                    <v-btn color="primary" :loading="saving" rounded :disabled="!canSubmit" class="px-5 mt-0" @click.native="save(false)">{{ (!item.optout) ? "Save Contact and Notify Them" : "Save Contact Without Notification" }}</v-btn>
                                </p>
                            </v-col>
                        </v-row>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
        </v-card-text>
        <v-card-actions class="card-actions mt-1">
            <v-spacer />
            <v-btn text @click.native="$emit('cancel')">Cancel</v-btn>
            <v-btn outlined v-if="step==2" class="ml-5" text @click.native="--step">Go Back</v-btn>
            <v-btn color="primary" :loading="saving" v-if="step==1" :disabled="!canSubmit" class="px-5 ml-5" @click.native="save(true)">Continue</v-btn>
            <!-- <v-btn color="primary" :loading="saving" v-else dark :disabled="!canSubmit" class="px-5 ml-5" @click.native="save()">Save Contact</v-btn> -->
            <v-spacer />
        </v-card-actions>
    </v-card>
</template>
<script>
    
import { mapState } from 'vuex'

export default {
    name: 'UserContactsAdd',
    components: {
        SMSSim: () => import('@/components/common/SMSSim'),
    },
    computed: {
        ...mapState(['user']),
        canSubmit(){
            return this.item.name.length && this.item.phone.length == 14 && this.phoneConfirm == this.item.phone;
        },
        smsMsg(){
            let msg = "<p class=\"caption\">Sent on behalf of " + this.user.name + " - " + this.user.phone;
            msg += "<br><br>Hi! I've added you as an emergency contact for an app I'm using to check in on me and make sure I'm okay.";
            msg += "<br><br>Should I fail to respond, you will receive a notification to let you know I may be missing or need your help.";
            msg += "<br><br>Contact me at " + this.user.phone + " if you have any questions. If you prefer not to receive these notifications, simply reply with \"stop\".";
            return [{ 
                content: msg
            }];
        },
    },
    data: () => ({
        errors: null,
        item: {
            name: '',
            phone: '',
            active: true,
            optout: false
        },
        phoneConfirm: '',
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        saving: false,
        showPreview: false,
        step: 1,
    }),
    methods: {
        initData(){
            
        },
        noself(value){
            return value && value !== this.user.phone || 'You can not add yourself as a contact.';
        },
        confirm(value){
            return value && value == this.item.phone || 'Numbers do not match.'
        },
        save(validateOnly){ 
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/contacts', 
                method: 'post',
                data: this.item,
                apiKey: this.user.api_token || null
            };
            if(validateOnly === true){
                request.data.validate_only = true;
            }else{
                request.data.validate_only = false;
            }
            this.$appFn.callApi(request).then((res)=>{
                if(validateOnly && res.status == 204){
                    ++this.step;
                }else if(res.status == 201){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your contact has been added.'
                    });
                    this.$emit('created');
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
                this.$eventHub.$emit('refreshUser');
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>