<template>
    <v-card class="elevation-4 mb-5">
        <v-card-title class="card-header text-truncate page">
            <v-icon color="primary" class="mr-1">fa fa-edit</v-icon> Update {{ item.name }}
        </v-card-title>
        <v-card-text class="px-5">
            <v-alert class="mt-2" dense type="error" v-if="errors">
                {{ errors }}
            </v-alert>
            <v-row class="mt-2">
                <v-col cols="12" md="8" lg="8" offset-md="2" offset-lg="2" class="pb-0">
                    <v-text-field
                        v-model="item.name"
                        label="Contact Name"
                        type="text"
                        :success="itemCopy.name !== item.name && item.name.length > 1"
                        placeholder="Ex: Mom, Alex, Mr. F"
                        :rules="[rules.required]"
                        outlined
                        validate-on-blur
                        hint="For reference only. Full names are not required."
                        v-on:keyup.enter="(canSubmit) ? save() : ''"
                    ></v-text-field>
                    <v-text-field
                        :value="item.phone"
                        label="Mobile Phone Number"
                        type="tel"
                        prefix="+1"
                        outlined
                        :success="itemCopy.phone !== item.phone && item.phone.length == 14"
                        placeholder="Ex: (555) 555 - 5555"
                        :rules="[rules.required]"
                        validate-on-blur
                        readonly
                        v-if="!changePhone"
                        v-mask="'(###) ###-####'"
                        @click="changePhone=true"
                    ></v-text-field>
                    <v-row dense v-if="changePhone">
                        <v-col cols="6">
                            <v-text-field
                                v-model="item.phone"
                                outlined
                                label="Mobile Phone Number"
                                type="tel"
                                class="mt-1"
                                prefix="+1"
                                :success="item.phone.length == 14"
                                placeholder="Ex: (555) 555 - 5555"
                                :rules="[rules.required, noself]"
                                validate-on-blur
                                v-mask="'(###) ###-####'"
                                hint="Must be able to receive text messages."
                            ></v-text-field>
                        </v-col>
                        <v-col cols="6">
                            <v-text-field
                                v-model="phoneConfirm"
                                outlined
                                label="Confirm Phone Number"
                                type="tel"
                                class="mt-1"
                                prefix="+1"
                                color="primary"
                                :success="phoneConfirm.length == 14 && phoneConfirm == item.phone"
                                placeholder="Ex: (555) 555 - 5555"
                                :rules="[rules.required, confirm]"
                                validate-on-blur
                                v-mask="'(###) ###-####'"
                                hint="Verify the correct phone number was entered."
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="8" lg="8" offset-md="2" offset-lg="2" class="pt-0">
                    <v-alert dark color="grey lighten-3">
                        <div class="flex-center">
                            <v-switch v-model="item.active" color="success" light class="mt-0" hide-details label="Contact is Active" />
                        </div>
                        <p class="body-2 text-center secondary--text mt-2 mt-md-3 px-3 mb-1">Only active contacts will receive notifications.</p>
                    </v-alert>
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions class="card-actions mt-1">
            <v-spacer />
            <v-btn color="d" text @click.native="$emit('cancel')">Cancel</v-btn>
            <v-btn color="primary" :loading="saving" :disabled="!canSubmit" class="px-5 ml-5" @click.native="save()">Save Contact</v-btn>
            <v-spacer />
        </v-card-actions>
    </v-card>
</template>
<script>
    
import { mapState } from 'vuex'

export default {
    name: 'UserContactsUpdate',
    props: {
        item: {
            required: true,
            type: Object
        }
    },
    computed: {
        ...mapState(['user']),
        canSubmit(){
            return this.item.name.length && this.item.phone.length == 14 && (!this.changePhone || this.phoneConfirm == this.item.phone);
        }
    },
    data: () => ({
        changePhone: false,
        errors: null,
        itemCopy: {},
        rules: {
            required: value => !!value || 'Required.',
            minlength: value => value && value.length > 5 || 'Must be at least 6 characters.',
        },
        phoneConfirm: '',
        saving: false,
    }),
    methods: {
        initData(){
            this.itemCopy = JSON.parse(JSON.stringify(this.item));
        },
        noself(value){
            return value && value !== this.user.phone || 'You can not add yourself as a contact.';
        },
        confirm(value){
            return value && value == this.item.phone || 'Numbers do not match.'
        },
        save(){ 
            this.saving = true;
            this.errors = null;
            let request = {
                endpoint: '/contacts/' + this.item.uuid, 
                method: 'put',
                data: this.item,
                apiKey: this.user.api_token || null
            };
            this.$appFn.callApi(request).then((res)=>{
                if(res.status == 200){
                    this.$eventHub.$emit('snackBar', {
                        text: 'Your contact has been updated!'
                    });
                    this.$emit('refreshUser');
                    this.$emit('updated');
                }else{
                    this.handleErrorResponse(res);
                }
            }).catch(()=>{
                this.errors = "Something went wrong! Please try again.";
            }).finally(()=>{
                this.saving = false;
            });
        },
        handleErrorResponse(res){
            this.errors = '';
            for(let i in res.data.errors){
                this.errors += res.data.errors[i];
            }
        },
    },
    mounted(){
		this.initData();
	},
    watch: {
    }
}
</script>