<template>
    <v-card color="white" :class="(!items.length) ? 'mb-3' : 'mt-3'">
        <v-card-text class="info-card">
            <v-row dense>
                <v-col cols="12" md="12" lg="12">
                    <p class="text-center secondary--text title mt-3 mt-lg-2 mb-0">
                        <v-icon color="primary" class="mb-md-2" x-large>fa fa-address-book</v-icon>
                        <br>
                        Emergency Contacts
                    </p>
                </v-col>
                <v-col cols="12" md="10" lg="10" offset-lg="1" offset-md="1">
                    <p class="secondary--text lh-13 text-center text-md-center subtitle-1 mb-1">
                        Your emergency contacts are the people we should attempt to notify if you fail to check in after your set time interval. While we recommend multiple contacts, only one is required.
                    </p>
                    <v-divider class="my-3" />
                    <p class="secondary--text lh-13 subtitle-1 text-center text-md-center mb-1">
                        <v-icon color="primary" small class="mr-1">fa fa-info-circle</v-icon> All active contacts will be notified should you fail to check in.
                    </p>
                    <p class="secondary--text lh-13 subtitle-1 text-center text-md-center mb-1">
                        <v-icon color="primary" small class="mr-1">fa fa-info-circle</v-icon> You can add up to 4 emergency contacts.
                    </p>
                    <p class="secondary--text lh-13 subtitle-1 text-center text-md-center mb-1">
                        <v-icon color="primary" small class="mr-1">fa fa-lock</v-icon> Your contacts are only visible to you.
                    </p>
                    <p class="text-center mt-3 mb-1" v-if="!items.length">
                        <v-divider class="my-4" />
                        <v-btn rounded color="primary" @click.native="$emit('add')">
                            <v-icon small class="mr-2" color="white">fa fa-plus</v-icon>
                            <span class="white--text">Add a Contact</span>
                        </v-btn>
                    </p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>
<script>

export default {
    name: 'UserContactsInfo',
    props: {
        items: {
            required: true,
            type: Array
        }
    },
    computed: {
    },
    data: () => ({
    }),
    methods: {
    },
    mounted(){
	},
    watch: {
    }
}
</script>