<template>
    <div>
        <v-toolbar class="sub-bar">
            <v-toolbar-title>
                <v-icon>fa fa-address-book</v-icon> Your Emergency Contacts
            </v-toolbar-title>
            <v-btn absolute right small rounded color="primary" class="mt-05 hidden-sm-and-down" @click.native="view='add'" :disabled="view!='list' || isUpdating || user.contacts.length > 3">
                <v-icon small class="mr-0 mr-sm-2" color="white">fa fa-plus</v-icon> 
                <span class="hidden-xs-only">Add a Contact</span>
            </v-btn>
        </v-toolbar>
        <v-row class="sub-cont">
            <v-col cols="3" class="hidden-sm-and-down pr-0">
                <LeftNav />
            </v-col>
            <v-col cols="12" md="9" v-if="view=='add'">
                <Add @created="view='list'" @cancel="view='list'" />
            </v-col>
            <v-col cols="12" md="9" v-if="view=='list' && !updateItem">
                <List @updating="handleUpdating($event)" :items="user.contacts" @add="view='add'" />
            </v-col>
        </v-row>
    </div>
</template>
<script>
    
import { mapState } from 'vuex'
import Add from '@/components/user/contacts/Add'
import LeftNav from '@/components/ui/LeftNav';
import List from '@/components/user/contacts/List'

export default {
    name: 'UserContacts',
    components: {
        Add,
        LeftNav,
        List,
    },
    computed: {
        ...mapState(['user']),
    },
    data: () => ({
        isUpdating: false,
        items: [],
        updateId: null,
        updateItem: null,
        view: 'list',
    }),
    methods: {
        initData(){
            this.$appFn.setTitle("Emergency Contacts - I'm Okay", true);
        },
        handleUpdating(updating){
            this.isUpdating=updating;
            setTimeout(() => {
                document.body.scrollTop=0;
            }, 20);
        }
    },
    mounted(){
		this.initData();
	},
    watch: {
        
    }
}
</script>